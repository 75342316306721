@import '../../styles/vars';
.popup {
	min-width: 320px;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 35px;
	font-family: $ff-headline;
}

.title {
	font-size: 22px;
	font-weight: 500;
	max-width: 250px;
	margin-bottom: 10px;
	text-align: center;
}

.subtitle {
	font-size: 22px;
	font-weight: 500;
	max-width: 250px;
	text-align: center;
}

.buttonsContainer {
	display: flex;
	margin-top: 15px;
}

.buttonWrapper {
	margin-right: 10px;

	&:last-of-type {
		margin-right: 0px;
	}
}