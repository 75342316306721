@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/mixins';
@import '../../styles/vars';

.template {
	display: flex;
	align-items: center;
	justify-content: center;
	//height: 100%;
	width: $calcWidth;
	padding: $templatePadding;
	color: white;
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;
}

.templateCol {
	flex: 1;
	margin-left: 25px;
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 28px;
	color: $light-color;
	position: relative;
	top: -40px;
}

.templateRow {
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 760px;
	@include mq(m) {
		padding: 30px 0;
	}
}

.question {
	color: $text-color;
	width: 100%;
	margin-bottom: 30px;
	max-width: $calcWidth;
	margin-left: auto;
	margin-right: auto;
	word-break: break-word;
	&.withDescription {
		margin-bottom: 10px;
	}
}

.description {
	padding: 0 0 30px;
	@include mq(m) {
		margin-left: auto;
		margin-right: auto;
		width: 90%;
		font-size: $fs-description;
	}
	& > p {
		padding: 0;
		margin: 0;
	}
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
	flex-wrap: wrap;
	padding: 0;

	@include mq(m) {
		justify-content: center;
	}
}

.buttonWrapper {
	margin: 1%;
	width: 48%;
	cursor: pointer;
	flex-wrap: wrap;

	@include mq(m) {
		width: 30%;
	}
}

.buttonWrapperSmall {
	//
	//@include mq(s) {
	//  width: 30%;
	//}
	@include mq(m) {
		width: 23%;
	}
}

.answerColumn {
	display: flex;
	flex-direction: column;
	background-color: white;
	position: relative;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 14%);
	padding: 0;
	border-radius: $border-radius;

	&.imagesTemplate {
		padding: 10px;
		display: block;

		@include mq(m) {
			padding: 15px
		}

		.answerLabel {
			display: block;
			margin: 0;
			padding: 15px 0 10px;
			word-break: break-word;
		}
	}

	button {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding: 10px;
		font-size: 13px;
		min-height: 20px;

		@include mq(m) {
			font-size: 16px;
		}
	}
	.answerLabel {
		font-weight: $fw-bold;
		font-size: $fs-button-lg;
		padding-bottom: 15px;
		word-break: break-word;

		@include mq(m) {
			font-size: $fs-button-md;
			padding-top: 15px;
			padding-bottom: 25px;
		}

		@include mq(l) {
			font-size: $fs-button-lg;
			padding-top: 15px;
			padding-bottom: 25px;
		}

		@include mq(xl) {
			font-size: $fs-button-lg;
		}

		// answer Label for columns template
		&.columnTemplate {
			padding: 10px;
			font-size: calc(#{$fs-body} - 2px);
			margin: 15px 12px;
			border-radius: 30px;

			@include mq(l) {
				margin: 20px;
			}
		}
	}
}

.image {
	margin: 0;
	width: inherit;
	object-fit: cover;
	border-radius: $border-radius $border-radius 0 0;
	height: auto;
	aspect-ratio: auto 16 / 9;

	&.imagesTemplate {
		border-radius: $border-radius;
		height: auto;
		width: 100%;
		object-fit: cover;
		margin: 0;
	}
}

span.priceBubble {
	position: absolute;
	top: 110px;
	right: 12px;
	width: 58px;
	height: 58px;
	border-radius: 50%;
	background-color: $text-color;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	& > span {
		font-size: 16px;
	}
	@include mq(m) {
		top: 138px;
	}
}

.priceBubbleSmall {
	top: 115px;
	right: 9px;
	width: 52px;
	height: 52px;
	font-size: 14px;
	span {
		font-size: 14px;
	}
}

.priceLabel {
	//font-size: 16px;
	display: block;
}

.priceLabelSmall {
	//font-size: 14px;
}

.price {
	font-size: 16px;
	font-weight: bold;
}

.priceSmall {
	font-size: 14px;
}

.imageTitle {
	display: block;
	color: $text-color;
	font-size: 24px;
	font-weight: bold;
	text-align: left;
	margin-bottom: 0;
	margin-top: 0;
	padding: 12px 12px 0;

	@include mq(l) {
		padding: 20px 20px 0;
	}
}

.checkmark {
	display: flex;
	width: 8px;
	height: 8px;
	margin-right: 3px;
	margin-left: 7px;
	justify-content: center;
	align-items: flex-start;
}

.richText {
	display: flex;
	flex-direction: column;
	flex: 1;
	font-size: 16px !important;
	color: $text-color;
	text-align: left;
	padding: 12px;
	// margin-left: auto;
	// margin-right: auto;

	@include mq(l) {
		padding: 12px 20px;
	}

	p {
		margin: 0;
	}

	strong {
		font-weight: bold;
	}
	em {
		font-style: italic;
	}
	ul {
		padding-left: 0;

		li {
			padding-left: 5px;
			list-style-type: disc;
			list-style-position: inside;
		}

		li:not(.ql-direction-rtl) {
			padding-left: 5px;
		}

		li::marker {
			margin: 0 5px 0 -1px;
		}
	}

	ul > li:before {
		content: none;
	}

	ol {
		padding-left: 0;

		li {
			padding-left: 5px;
			list-style-type: decimal;
			list-style-position: inside;
		}
		li:not(.ql-direction-rtl) {
			padding-left: 5px;
		}
	}
}

.flexCol {
	flex-direction: column;
	justify-content: center;
}