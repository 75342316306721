@import "../../styles/colors";
@import "../../styles/placeholders";
@import '../../styles/mixins';
@import '../../styles/vars';

.template {
  display: flex;
	align-items: center;
	justify-content: center;
	//height: 100%;
	width: $calcWidth;
	padding: $templatePadding;
	color: white;
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;
}

.titleWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  font-size: 14px;
  color: $light-color;
  position: relative;
  top: -40px;
}

.templateRow {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.question {
  font-size: 18px;
  color: $text-color;
  width: 100%;
  line-height: 22px;
  margin: 30px 0 12px;
}

.inputRow {
  display: flex;
  margin-top: -3px;
}

.inputWrapper {
  width: 100%;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.buttonWrapper {
  width: 100%;

  button {
    margin-top: 20px;
    width: 100%;
  }
}

.secondSceneButtonWrapper {
	width: 50%;
	margin: 0 auto;
  position: relative;
  top: 10px;

	button {
		width: 100%;
	}
}

.flexCol {
  flex-direction: column;
  justify-content: center;
}

.error {
  width: 100%;
  font-size: 7px;
  color: $error-color;
  visibility: hidden;
  text-align: center;
  position: absolute;
  bottom: -6px;
}

.show {
  visibility: initial;
}

.warning {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 30px;
  background: grey;
  color: $text-color;
  font-size: 9px;
  font-weight: bold;
  background-image: url("../../assets/images/TemplateBg.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
  -webkit-filter: none;
  display: flex;
  align-items: center;
}

.source {
	font-size: 14px !important;
	font-style: italic;
	margin-top: 40px;
}

.suggestions {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  font-size: 10px;
  border-radius: 2px 2px 4px 4px;
  border-top-width: 0;
  box-shadow: 0 0 3px #999;
  list-style: none;
  margin-top: 0;
  max-height: 48px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.suggestions ul {
  //max-height: 100px;
  // max-width: 200px;
  margin: 0;
  padding: 0;
  overflow: auto;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;

  li {
    height: 24px;
    background-color: #fff;
    border-bottom: 1px dotted $light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
  }
}

.suggestion-active,
.suggestions li:hover {
  background-color: #0085ff;
  color: #f9f9f9;
  cursor: pointer;
  font-weight: 700;
}

.loader{
  position: relative;
  top: 150px;
}

.description{
  p {
    margin: 0 0 12px;
  }
}