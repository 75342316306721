@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/mixins';
@import '../../styles/vars';

._clickMaster {
	&.ContactTemplate {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	//height: 100%;
	width: $calcWidth;
	margin-left: auto;
	margin-right: auto;
	padding: $templatePadding;
	color: white;
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;

	button {
		//padding: 5px 10px;
		min-height: 15px;
		min-width: 100px;
	}
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 14px;
	color: $light-color;
	position: relative;
	top: -40px;
}

.templateRow {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.question {
	color: $text-color;
	width: 100%;
	margin: 30px 0 12px;
}

.description {
	margin-top: 0;
	margin-bottom: 5px;
	color: $text-color;
	p {
		margin: 0 0 12px;
	}
}

.inputRow {
	display: flex;
  flex-flow: column wrap;
	// flex-direction: column;
  // flex-wrap: wrap;
	//margin-bottom: 5px;

	@include mq(m) {
		flex-direction: row;
	}
}

.inputWrapper {
  // width: 100%;
  padding: 0 5px;
}

.width-33 {
  width: 100%;
  @include mq(m) {
    width: 33.3%;
  }
}
.width-50 {
  width: 100%;
  @include mq(m) {
    width: 49.9%;
  }
}
.width-66 {
  width: 100%;
  @include mq(m) {
    width: 66.6%;
  }
}
.width-100 {
  width: 100%;
}

.dropdown {
  position: relative;
	padding: 15px;
	margin: 5px 0;
	border-radius: 30px;
	text-align: left;
	background-color: $grey-light;
	border: 1px solid #ededed;

  select {
    margin: -5px 0;
    width: 100%;
    // color: $text-color;
		font-family: inherit;
    font-size: 20px;
		text-align: center;
    background-color: $grey-light;
    border: none;
    border-radius: 5px;
  }

}
.lightText {
	color: $light-color;
}
.darkText {
	color: $text-color;
}

.dropdownError {
	position: relative;
	margin-top: -14px;
	color: $error-color;
	font-size: 12px;
	text-align: center;
}

.margin {
	@include mq(m) {
		margin-left: 8.9px;
	}
}

.checkboxContainer {
	position: relative;
	margin-left: 15px;
	width: 97%;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	margin-top: 4px;

	input {
		margin-left: 0px;
		border: none;
	}
}

.checkboxLink {
	display: inline-block;
	color: $text-color;
	margin-left: 3px;
	margin-top: 5px;
	@extend %global-transition;
	text-decoration: none;
	text-align: left;

	p {
		padding: 0;
		margin: 5px 0;
		strong {
			font-weight: bold;
		}
		em {
			font-style: italic;
		}
	}

	a {
		color: inherit;
		text-decoration: underline;
	}

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;
	}
}

.dateContainer {
	font-size: 12px;
	color: $text-color;
	margin: 5px 0;
	padding: 5px;
	border-radius: 30px;
	background-color: $grey-light;
}

.multiChoiceLabel{
	color: $text-color;
	font-weight: bold;
	text-align: left;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.buttonWrapper {
	margin-top: 12px;
	//width: 100%;
	display: flex;
	justify-content: center;

	button {
		align-self: center;

		@include mq(l){
			max-width: 400px;
		}
	}
}

.error {
	width: 100%;
	font-size: 12px;
	color: $error-color;
	visibility: hidden;
	text-align: center;
	position: absolute;
	bottom: -12px;

	&.tos_error {
		text-align: left;
		top: 0;
		left: 25px;
		position: relative;
	}
}

.show {
	visibility: initial;
}

input[type='checkbox'] {
	-webkit-transform: scale(1.5, 1.5);
	margin-right: 10px;
}

}