.DateInput_input {
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #4d4f5c;
    background-color: #F4F4F4;
    box-sizing: content-box;
}
.DateInput_input::placeholder {
    color: #a6a7ad;
}