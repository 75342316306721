@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/vars';

._dbw.inputWrapper {
	width: 100%;
	position: relative;

	::placeholder {
		color: $light-color;
	}
}

.inputWrapperTemplate {
	::placeholder {
		color: $text-color;
	}
}

.input {
	position: relative;
	outline: none;
	width: 100%;
	border: 1px solid $light-color;
	padding: 10px 0;
	font-size: $fs-body;
	font-family: inherit;
	color: $text-color;
	@extend %global-transition;

	&:focus,
	&:active {
		border-color: $primary-color;
	}
}

.textarea {
	resize: none;
}

.error._dbw {
	position: absolute;
	width: 100%;
	top: 43px;
	left: 0;
	font-size: 12px;
	color: $error-color;
	visibility: hidden;
}

.show._dbw {
	visibility: initial;
}

.primary {
	border-radius: 30px;
	padding: 18px 35px 18px 65px;
	font-weight: 300;
}

.icon {
	position: absolute;
	top: 18px;
	left: 28px;
	width: 23px;
	height: 20px;
	z-index: 2;
}

.errorPrimary {
	top: 60px;
	left: 0;
	text-align: center;
}

.secondary {
	border-radius: 5px;
	border: 1px solid #ededed;
	filter: drop-shadow(0px 2px 5px #ededed);
	-webkit-filter: none;
	padding: 15px;
	font-size: 15px;
}

.errorSecondary {
	top: 52px;
	left: 15px;
	text-align: left;
}

.ternary {
	padding: 8px;
	background-color: transparent;
	border: 1px solid $light-color;
	border-radius: 5px;
}

.errorTernary {
	top: 40px;
	left: 8px;
	text-align: left;
}

.template {
	padding: 15px 0;
	margin: 5px 0;
	border-radius: 30px;
	text-align: center;
	background-color: $grey-light;
	border: 1px solid #ededed;
}
.templateTextarea {
	padding: 15px 0;
	margin: 5px 0;
	border-radius: 30px;
	text-align: center;
	background-color: $grey-light;
	border: 1px solid #ededed;
	font-family: inherit;
}