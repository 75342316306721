@font-face {
	font-family: Work Sans;
	src: url('../assets/fonts/Work_Sans/static/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Work Sans;
	src: url('../assets/fonts/Work_Sans/static/WorkSans-Italic.ttf') format('truetype');
	font-style: italic;
}

//@font-face {
//	font-family: Work Sans;
//	src: url('../assets/fonts/Work_Sans/static/WorkSans-Bold.ttf') format('truetype');
//	font-weight: 700;
//}
//
//@font-face {
//	font-family: Work Sans;
//	src: url('../assets/fonts/Work_Sans/static/WorkSans-BoldItalic.ttf') format('truetype');
//	font-weight: 700;
//	font-style: italic;
//}

@font-face {
	font-family: Work Sans;
	src: url('../assets/fonts/Work_Sans/static/WorkSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: Work Sans;
	src: url('../assets/fonts/Work_Sans/static/WorkSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: Work Sans;
	src: url('../assets/fonts/Work_Sans/static/WorkSans-Light.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: Work Sans;
	src: url('../assets/fonts/Work_Sans/static/WorkSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}