@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/mixins';
@import '../../styles/vars';

._clickMaster {
	&.ContactTemplate {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: $calcWidth;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
	padding: $templatePadding;
	color: white;
	background-size: cover;
	background-position: center;
	text-align: center;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
	-webkit-filter: none;

	button {
		//padding: 5px 10px;
		min-height: 15px;
		min-width: 100px;
	}
}

.titleWrapper {
	position: absolute;
	top: 0;
	left: 0;
}

.title {
	font-size: 14px;
	color: $light-color;
	position: relative;
	//top: -40px;
}

.templateRow {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.question {
	color: $text-color;
	width: 100%;
	margin: 30px 0 12px;
}

.description {
	color: $text-color;
	// margin-top: -40px;
	p {
		margin: 0 0 12px;
	}
}

.inputRow {
	display: flex;
  flex-flow: column wrap;

	@include mq(m) {
		flex-direction: row;
	}
}

.lightText {
	color: $light-color;
}
.darkText {
	color: $text-color;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.buttonWrapper {
	margin-top: 12px;
	width: 100%;
	display: flex;
	justify-content: center;

	button {
		width: 100%;
		max-width: 400px;
		align-self: center;
	}
}

}