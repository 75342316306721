@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/z_indexes';
@import '../../styles/animations';
@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/fonts';

._clickMaster {
	position: relative;

	body,
	p,
	span,
	div {
		font-weight: $fw-slim;
		font-family: $ff-headline;
		font-size: $fs-body;
	}

	// Headlines
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: $ff-headline;
		font-weight: $fw-bold;
		line-height: 1.2em;
	}

	h1,
	.h1,
	.question {
		font-size: $fs-h1;

		@include mq(m) {
			font-size: $fs-h1-md;
		}
		@include mq(l) {
			font-size: $fs-h1-lg;
		}
	}

	h3 {
		font-size: $fs-h3;

		@include mq(m) {
			font-size: $fs-h3-lg;
		}
	}

	.overlay {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		@include mq(m) {
			display: none;
		}

		&.popupOpen {
			display: none;
		}
	}

	.headerBar {
		background-color: #eee;
		width: 100%;
		height: 70px;
		display: none;
		align-items: center;
		justify-content: flex-end;
		flex: auto;
		border-bottom: 1px solid #bcbcbc;
		bottom: 90px;
		@include mq(m) {
			display: none;
		}

		&.popupOpen {
			display: flex;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 9;
		}
	}

	.closeWidget {
		font-size: 45px;
		color: $grey;
		text-align: right;
		cursor: pointer;
		transition: color 0.3s ease;
		position: absolute;
		right: 20px;
		bottom: 7px;
		margin-bottom: 4px;
	}

	&.container {
		flex: 1;
		display: flex;
		position: relative;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		&.popupOpen {
			min-height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			overflow-y: auto;
		}

		@include mq(m) {
			padding-top: 0;
		}
	}

	.templateImage {
		display: flex;
		align-items: center;
	}

	.video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 10px;
	}

	.backButton {
		position: absolute;
		top: 10px;
		left: 12px;
		cursor: pointer;
		z-index: 1;
		padding-left: 0;
		transition: opacity 200ms ease;

		&:hover{
			opacity: .5;
		}
	}

	.templateCol {
		flex: 1;
		margin-left: 45%;
	}

	.image {
		width: 45%;
		height: inherit;
		border-radius: 0;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
	}

	.priceBubble {
		position: absolute;
		top: 140px;
		left: 89px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: $text-color;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.priceLabel {
		font-size: 7px;
		display: block;
		margin-bottom: 2px;
	}

	.price {
		font-size: 9px;
		font-weight: bold;
	}

	.titleWrapper {
		position: absolute;
		top: 0;
		left: 0;
	}

	.title {
		font-size: 14px;
		color: $light-color;
		position: relative;
		top: -40px;
	}

	.templateRow {
		width: 100%;
	}

	.question {
		color: $text-color;
		width: 100%;
		margin-bottom: 12px;
		font-weight: bold;
		font-size: 34px;
		line-height: 40px;
	}

	.questionImage {
		text-align: left;
		margin-bottom: 8px;
	}

	.templateContainer {
		// @include mq(m) {
		//   all: unset;
		// }
		width: 742px;
		height: 400px;
		opacity: 1;
	}

	.popup {
		height: 100%;
		margin: 0;
		top: 0;
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 380px;
		width: 100%;
		color: white;
		background: grey url('../../assets/images/TemplateBg.png') center;
		text-align: center;
		filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
		-webkit-filter: none;
		background-size: cover;
	}

	.topRight {
		z-index: 1;
	}
}