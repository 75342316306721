$base-blue-color: #0085ff;
$base-white-color: #ffffff;
$base-orange-color: #fa991c;
$base-grey-color: #4d4f5c;
$base-light-grey-color: #a6a7ad;

$primary-color: $base-blue-color;
$secondary-color: $base-white-color;
$accent-color: $base-orange-color;
$text-color: $base-grey-color;
$light-color: $base-light-grey-color;
$light-grey: #eee;

$background-color: #f4f4f4;
$light-background-color: #fbfbfb;
$error-color: #ff0000;

$grey-light: #F4F4F4;
$grey: #777;

//$shadow-color: #e0e2e4;
