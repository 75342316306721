@import "mixins";
$global-navbar-height-mobile: 80px;
$global-navbar-height-tablet: 80px;
$global-navbar-height-desktop: 80px;

$global-sidebar-width-desktop: 330px;

$fs-h1: 9vw;
$fs-h1-md: 45px;
$fs-h1-lg: 45px;
$fs-h1-xl: 45px;
$fs-h2: 28px;
$fs-h2-lg: 40px;
$fs-h3: 22px;
$fs-h3-lg: 34px;
$fs-body: 20px;

$fs-description: 26px;

$fs-button: 5vw;
$fs-button-md: 18px;
$fs-button-lg: 20px;
$fs-button-xl: 24px;


$ff-headline: "Work Sans", sans-serif;

$fw-slim: 300;
$fw-bold: 600;

$border-radius: 10px;

// Width
$calcMargin: 15px;
$calcWidth: calc(100% - #{$calcMargin} * 2);
$templatePadding: 40px 0 70px;