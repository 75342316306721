@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/mixins';
@import '../../styles/vars';

.button {
	border: none;
	outline: none;
	color: white;
	font-weight: $fw-bold;
	font-size: $fs-body;
	@extend %global-transition;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-family: $ff-headline;
	margin: 0;
}

.primary {
	background-color: $primary-color;
	font-size: 18px;
	border-radius: 30px;
	padding: 15px 15px;

	@include mq(m) {
		padding: 20px 35px;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: darken($primary-color, 10%);
	}
}

.accent {
	background-color: $accent-color;

	&:hover,
	&:focus,
	&:active {
		background-color: darken($accent-color, 10%);
	}
}
.disabled {
	opacity: .6;
	cursor: not-allowed;
}

.secondary {
	background-color: $text-color;
	padding: 15px 20px;
	border-radius: 45px;
	min-width: 130px;
	min-height: 26px;
	width: 100%;

	@include mq(m) {
		min-width: 160px;
	}
}

.icon {
	width: 18px;
	margin-right: 15px;
}

.small {
	padding: 10px 33px;
	font-size: 15px;
	min-width: 100px;
	border-radius: 30px;
}