video::-webkit-media-controls-current-time-display {
	display: none !important;
}
video::-webkit-media-controls-time-remaining-display {
	display: none !important;
}
video::-webkit-media-controls-timeline {
	display: none !important;
}

video::-webkit-media-controls-fullscreen-button {
	display: none;
}

.videoPlayer video {
	z-index: 3;
	border-radius: 25px;
	position: relative;
}

.bgImageWrapper{
	width: 100%;
	justify-content: center;
	position: relative;
}
.backgroundImage{
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
}

figure button[name='play'] {
	width: 75px;
	height: 75px;
	background: #0085ff;
	border: none;
	border-radius: 100%;
	margin: auto;
	cursor: pointer;
}
figure button[name='play']:focus {
	outline: 0;
	border: 1px solid hsl(210, 58%, 69%);
	box-shadow: 0 0 0 3px hsla(210, 76%, 57%, 0.5);
}

figure button[name='play']::after {
	content: '';
	display: inline-block;
	position: relative;
	top: 1px;
	left: 3px;
	border-style: solid;
	border-width: 10px 0 10px 20px;
	border-color: transparent transparent transparent white;
}

.displayNone {
	display: none;
}

.playButton {
	background: transparent;
	position: absolute;
	top: 0;
	left: 50%;
	height: 100%;
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	align-self: center;
	z-index: 4;
	transform: translate(-100%);
	margin-top: 0;
	margin-bottom: 0;
}