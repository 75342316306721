@import '../../styles/mixins';

//.audioPlayerPosition {
//  width: 250px;
//  height: 19px;
//  display: inline-block;
//  position: absolute;
//  left: 50%;
//  transform: translateX(-50%);
//  border: none;
//}

audio::-webkit-media-controls-current-time-display {
	display: none !important;
}
audio::-webkit-media-controls-time-remaining-display {
	display: none !important;
}
// audio::-webkit-media-controls-timeline {
// 	display: none !important;
// }

audio::-moz-media-controls-time-remaining-display {
	display: none !important;
}

.playButton {
	height: 200px;
	display: -ms-flex;
	display: -webkit-flex;
	display: flex;
	z-index: 4;
}

figure button[name='play'] {
	width: 75px;
	height: 75px;
	background: #0085ff;
	border: none;
	border-radius: 100%;
	margin: auto;
	cursor: pointer;
}
figure button[name='play']:focus {
	outline: 0;
	border: 1px solid hsl(210, 58%, 69%);
	box-shadow: 0 0 0 3px hsla(210, 76%, 57%, 0.5);
}

figure button[name='play']::after {
	content: '';
	display: inline-block;
	position: relative;
	top: 1px;
	left: 3px;
	border-style: solid;
	border-width: 15px 0 15px 25px;
	border-color: transparent transparent transparent white;
}

.hideAudioControls {
	display: none;
}

.playButton {
	background: rgba(255, 244, 222, 0.4);
	width: 100%;
	height: 100%;
	position: absolute;
	left: -40px;
	bottom: 10px;

	@include mq(m) {
		bottom: -20px;
	}
}


.newAudioPlayer {
	width: 20px;
	height: 20px;
	border: 1px solid red;
	z-index: 30;
}

.muteContainer {
	width: 60px;
	height: 60px;
	position: absolute;
	right: 20px;
	cursor: pointer;
	transition: opacity 200ms ease;
	 &:hover {
		 opacity: .5;
	 }
}