@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/mixins';
@import '../../styles/vars';

._clickMaster {
	&.template {
		display: flex;
		justify-content: flex-start;
		min-height: calc(100vh - 150px);
		width: $calcWidth;
		padding: $templatePadding;
		color: white;
		//background-image: url('../../assets/images/TemplateBg.png');
		background-position: center center;
		background-size: cover;
		text-align: center;
		filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
		-webkit-filter: none;
		@include mq(m) {
			min-height: 380px;
			padding: 70px 0;
		}

		&.templateImage {
			display: flex;
			align-items: center;
			flex-direction: column;
			overflow: auto;
			margin-top: 30px;

			@include mq(m) {
				flex-direction: row;
				margin-top: 0;
				padding: 70px 0;
				width: 100%;
			}
		}
	}

	//
	//.video {
	//	width: 100%;
	//	height: 100%;
	//	position: absolute;
	//	top: 0;
	//	left: 0;
	//	z-index: -1;
	//	border-radius: 10px;
	//}

	.templateCol {
		flex: 1;

		@include mq(m) {
			margin-left: 20px;
			padding: 30px;
		}
	}

	.imgContainer {
		width: 100%;

		position: relative;
		@include mq(m) {
			max-width: 450px;
			padding-left: 30px;
		}
	}

	.image {
		width: 100%;
		height: inherit;
		border-radius: 30px;
		object-fit: cover;
		padding: 0;
		left: 0;
	}

	.priceBubble {
		position: absolute;
		right: 10%;
		bottom: 10%;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background-color: $text-color;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.priceLabel {
    font-size: 14px;
		display: block;
		margin-bottom: 2px;
	}

	.price {
    font-size: 16px;
		font-weight: bold;
	}

	.titleWrapper {
		position: absolute;
		top: 0;
		left: 0;
	}

	.title {
		font-size: 24px;
		color: $light-color;
		position: relative;
		top: -40px;
	}

	.templateRow {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.question {
		color: $text-color;
		width: 96%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 12px;
		font-weight: $fw-bold;
		word-break: break-word;
	}

	.questionImage {
		margin-top: 15px;
		text-align: left;
		margin-bottom: 8px;
		margin-left: 0;

		@include mq(m) {
			margin-top: 0;
		}
	}

	.buttonsContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		flex-wrap: wrap;

		@include mq(m) {
			flex-direction: row;
		}

		&.withImage {
			flex-direction: column;
			justify-content: start;
			align-items: stretch;

			//@include mq(s) {
			//  flex-direction: column;
			//}
		}
	}

	.buttonWrapper {
		margin-top: 20px;

		@include mq(m) {
			margin-right: 20px;
		}
	}

	.buttonWrapperSmall {
		margin-top: 20px;
	}

	.description {
		flex: 1;
		font-size: $fs-body;
		color: $text-color;
		line-height: 1.2;
		padding: 0 10px 0 0;
		margin-bottom: 5px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		@include mq(m) {
			width: 85%;
		}

		&.withImage {
			text-align: left;
			margin-left: 0;
		}

		p {
			margin: 0;
		}

		@include mq(m) {
			font-size: 18px;
		}

		strong {
			font-weight: bold;
		}

		em {
			font-style: italic;
		}

		ul {
			padding-left: 0;

			li {
				padding-left: 10px;
				list-style-type: disc;
				list-style-position: inside;
			}
		}

		ol {
			padding-left: 0;

			li {
				padding-left: 10px;
				list-style-type: decimal;
				list-style-position: inside;
			}
		}

		li::before {
			display: none;
		}
	}
}

.lottie {
	width: 200px;
	height: 200px;
	position: relative;
	left: 50px;
}