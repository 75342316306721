@mixin mq($point) {
  @if $point == xl {
    @media (min-width: 1366px) {
      @content;
    }
  } @else if $point == l {
    @media (min-width: 64em) {
      @content;
    }
  } @else if $point == m {
    @media (min-width: 767px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 37.5em) {
      @content;
    }
  } @else if $point == xs {
    @media (max-width: 37.5em) {
      @content;
    }
  }
}