@import '../../styles/colors';
@import '../../styles/placeholders';
@import '../../styles/mixins';
@import '../../styles/vars';


._clickMaster {

	&.videoTemplateWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: 70px;
		margin-bottom: 70px;
		width: $calcWidth;
		margin-left: auto;
		margin-right: auto;

		@include mq(m) {
			flex-direction: row;
		}
	}

	.template {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		padding: 30px;
		background: grey;
		color: white;
		background-image: url('../../assets/images/TemplateBg.png');
		background-size: cover;
		background-position: center;
		text-align: center;
		filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.071));
		-webkit-filter: none;
	}


	.titleWrapper {
		position: absolute;
		top: 0;
		left: 0;
	}

	.title {
		font-size: 14px;
		color: $light-color;
		position: relative;
		top: -40px;
	}

	.templateRow {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: auto;

		@include mq(m) {
			width: 100%;
			margin: 0;
		}
	}

	.question {
		text-align: left;
		color: $text-color;
		width: 96%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 12px;
		font-weight: 600;

		@include mq(m) {
			margin-left: 25px;
			margin-top: 0;
		}
	}

	.buttonsContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		flex-wrap: wrap;

		@include mq(m) {
			flex-direction: row;
			justify-content: left;
			padding-left: 25px;
		}

		&.withImage {
			flex-direction: column;
			justify-content: start;
			align-items: stretch;

		}
	}

	.buttonWrapper {
		margin-top: 15px;
		@include mq(m) {
			margin-right: 15px;
		}
	}

	.labels {
		display: flex;
		flex-direction: column;
		max-width: 100px;
	}

	.richText {
		flex: 1;
		color: $text-color;
		text-align: left;
		padding: 0 9px;
		margin-bottom: 4px;
		margin-top: 0;

		p {
			margin: 0 0 12px;
		}

		@include mq(m) {
			margin-left: 25px;
			padding-left: 0;
		}

		strong {
			font-weight: bold;
		}

		em {
			font-style: italic;
		}

		ul {
			padding-left: 0;

			li {
				padding-left: 5px;
				list-style-type: disc;
				list-style-position: inside;
			}

			li:not(.ql-direction-rtl) {
				padding-left: 5px;
			}

			li::marker {
				margin: 0 5px 0 -1px;
			}
		}

		ul > li:before {
			content: none;
		}

		ol {
			padding-left: 0;

			li {
				padding-left: 5px;
				list-style-type: decimal;
				list-style-position: inside;
			}

			li:not(.ql-direction-rtl) {
				padding-left: 5px;
			}
		}
	}

	.flexCol {
		flex-direction: column;
		justify-content: center;
	}

	.content {
		z-index: 2;
		left: 0;
		padding-top: 0;
		text-align: center;
		height: calc(100% - 50px);
		flex-direction: column;
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.hideQuestions {
		display: none;
	}

	.videoPlayer {
		z-index: 1;
	}
}